<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.Name || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <template name="dropdown-items">
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout()"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import firebase from 'firebase/app'

export default {
  beforeCreate() {
    const user = JSON.parse(localStorage.getItem('userName'))
    if (!user) {
      firebase.auth().signOut()
    }
  },
  methods: {
    logout() {
      firebase.auth().signOut()
    },
  },
  setup() {
    const user = JSON.parse(localStorage.getItem('userName'))
    const userData = ref({ Name: user.nome })
    return { userData, avatarText }
  },
}
</script>
