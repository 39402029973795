<template>
  <div 
    class="footerClass mt-2"
    :style="isDark ? 'background-color: #242A3D;' : 'background-color: #E9E9E9;'"
  >
    <div class="col-12 d-flex align-items-center justify-content-center">
      <img
        class="logo"
        src="../../../assets/images/logo/icon_branco.png"
        alt="icone branco"
      >
    </div>
  </div>
</template>
<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  name: 'FooterInfo',

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}

</script>
<style>
  .footerClass {
    height: 60px;
    padding: 0.8rem 1rem;
    border-radius: 0.428rem;
    display: flex;
    align-items: center;
  }
  .logo {
    height: 30px;
  }
</style>