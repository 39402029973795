<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      :id="`tooltip-target-${item.title}`"
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <span
        v-if="item.title === 'MINHA CONTA' && vencidos > 0"
        class="warning-box"
      >
        {{ vencidos }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const faturasScc4Store = createNamespacedHelpers('faturasScc4')
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { findAll } = faturasScc4Store.useActions(['findAll'])
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))

    const onSubmit = () => {
      findAll(empresaId).then()
    }

    const state = reactive({
      vencidos: faturasScc4Store.useGetters(['getQuantidadeVencidos']).getQuantidadeVencidos,
    })

    if (props.item.title === 'MINHA CONTA') {
      onSubmit()
    }

    return {
      ...toRefs(state),
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,
      // i18n
      t,
    }
  },

}
</script>

<style lang="scss">
.warning-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F55559;
  color: #FFFEFE;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.7rem;
  margin-left: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #FFFEFE;
}
</style>